<template>
  <v-footer
    padless
    app
    class="main-footer pa-2"
  >
    <j-divider />
    <!--
      <v-col
      cols="12"
      class="text-center"
      >
      &copy; 1991-{{ new Date().getFullYear() }}
      <a href="https://ink19.com">Ink 19</a>
      </v-col> 
    -->
  </v-footer>
</template>

<script>
export default {
  name: 'MainFooter'
}
</script>

<style lang="scss">

.main-footer {
  @extend %j-background-medium;
}

</style>
