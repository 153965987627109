<template>
  <div class="alerts">
    <j-alert
      v-for="error in errors"
      :key="error.title"
      :type="error.type || 'error'"
      :title="error.title"
      :message="error.message"
      dismissible
      class="mb-4"
    />
    <j-alert
      v-for="notice in notices"
      :key="notice.title"
      :type="notice.type || 'info'"
      :title="notice.title"
      :message="notice.message"
      dismissible
      class="mb-4"
    />
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: 'Alerts',
  computed: {
    notices() {
      return store.state.alerts.notices
    },
    errors() {
      return store.state.alerts.errors
    }
  }
}
</script>
