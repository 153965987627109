<template>
  <!-- Main Application Layout -->
  <v-app>
    <Navigation />

    <!-- Size content based on application components -->
    <v-main>
      <!-- Main Content -->
      <v-container>
        <!-- Alerts -->
        <Alerts />

        <!-- Router View -->
        <router-view />
      </v-container>
    </v-main>

    <main-footer />

    <!-- Superellipse SVG Clipping Path -->
    <svg
      width="0"
      height="0"
    >
      <defs>
        <clipPath
          id="superellipse"
          clipPathUnits="objectBoundingBox"
        >
          <path
            d="
            M 0,0.5
            C 0,0 0,0 0.5,0
              1,0 1,0 1,0.5
              1,1 1,1 0.5,1
              0,1 0,1 0,0.5
            "
          />
        </clipPath>
      </defs>
    </svg>
  </v-app>
</template>

<script>

import Alerts from '@/components/layout/Alerts'
import Footer from '@/components/layout/Footer'
import Navigation from '@/components/layout/Navigation'

export default {
  components: {
    Alerts,
    'main-footer': Footer, // avoid conflict with HTML <footer> tag
    Navigation
  }
}
</script>

<style lang="scss">

body {
  @extend %j-background-light;
}

#app {
  background: none;
} 

</style>
