<template>
  <v-app-bar
    color="#CCC"
    dense
    app
    class="top-navigation"
  >
    <v-toolbar-title @click="home">
      <img
        src="../../assets/images/ink19-logo.png"
        alt="Ink 19 Logo"
        class="mt-2"
      >
    </v-toolbar-title>

    <v-spacer />

    <!-- Dashboard -->
    <v-btn
      text
      class="mr-2"
      :to="{ name: 'dashboard' }"
      exact
    >
      Dashboard
    </v-btn>

    <!-- Articles -->
    <v-btn
      text
      class="mr-2"
      :to="{ name: 'articles-index' }"
    >
      Articles
    </v-btn>

    <!-- Assignments -->
    <v-btn
      v-if="$user.isAdmin"
      text
      class="mr-2"
      :to="{ name: 'assignments' }"
    >
      Assignments
    </v-btn>

    <!-- Opportunities -->
    <v-btn
      text
      class="mr-2"
      :to="{ name: 'opps-featured' }"
    >
      Opportunities
    </v-btn>

    <!-- Wiki -->
    <v-btn
      v-if="!$user.isAdmin"
      text
      class="mr-2"
      :to="{ name: 'wiki-index', params: { wiki: 'staff' } }"
    >
      Wiki
    </v-btn>

    <!-- More -->
    <v-menu 
      min-width="150px"
      offset-y
    >
      <template #activator="{ on }">
        <v-btn
          text
          class="mr-2"
          :class="{ 'v-btn--active': $route.name.includes('staff') }"
          v-on="on"
        >
          More
        </v-btn>
      </template>
      <v-list>
        <!-- Announcements -->
        <v-list-item
          :to="{ name: 'announcements-index' }"
          exact
        >
          <v-list-item-title class="menu-item">
            Announcements
          </v-list-item-title>
        </v-list-item>

        <!-- Staff Directory -->
        <v-list-item
          :to="{ name: 'staff-directory' }"
          exact
        >
          <v-list-item-title class="menu-item">
            Staff
          </v-list-item-title>
        </v-list-item>

        <!-- Sections -->
        <v-list-item
          :to="{ name: 'sections-directory' }"
          exact
        >
          <v-list-item-title class="menu-item">
            Sections
          </v-list-item-title>
        </v-list-item>

        <!-- Admin Stuff -->
        <template v-if="$user.isAdmin">
          <v-divider />

          <v-subheader>ADMIN</v-subheader>
          
          <!-- Publicists Directory -->
          <v-list-item
            :to="{ name: 'publicists-directory' }"
            exact
          >
            <v-list-item-title class="menu-item">
              Publicists
            </v-list-item-title>
          </v-list-item>

          <!-- Wiki (Admin) -->
          <v-list-item
            :to="{ name: 'wiki-index', params: { wiki: 'staff' } }"
            exact
          >
            <v-list-item-title class="menu-item">
              Wiki
            </v-list-item-title>
          </v-list-item>

          <!-- Resque -->
          <v-list-item
            @click="resque"
          >
            <v-list-item-title class="menu-item">
              Workers
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-divider />

        <!-- Version -->
        <v-list-item :to="{ name: 'release-notes' }">
          <v-list-item-title class="menu-item">
            About Kexy [v{{ version }}]
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Profile Dropdown -->
    <v-menu
      bottom
      offset-y
    >
      <!-- User Avatar -->
      <template #activator="{ on }">
        <v-btn
          icon
          class="mx-2"
          v-on="on"
        >
          <j-avatar
            :source="$user.profile"
            :alt="$user.initials"
            size="35"
          />
        </v-btn>
      </template>

      <v-list>
        <!-- Profile -->
        <v-list-item :to="{ name: 'contributor-view', params: { slug: $user.slug }}">
          <v-list-item-icon>
            <j-icon icon="user" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-item">
              My Profile
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Logout -->
        <v-list-item @click="logout">
          <v-list-item-icon>
            <j-icon icon="sign-out-alt" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-item">
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: 'Navigation',
  // -----------
  // Computed ==
  // -----------
  computed: {
    version() {
      return this.$store.state.auth.version
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    home() {
      this.$router.push({ name: 'home' })
    },
    resque() {
      window.open('/resque', '_blank')
    },
    logout() {
      this.$user.logout()
    }
  }
}
</script>

<style lang="scss">

.top-navigation {
  @extend %j-background-medium;
}

</style>
